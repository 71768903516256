import { Card, CardDeck, Col, Container, Row } from "react-bootstrap"
import { Link, withI18next } from "gatsby-plugin-i18next"
import { graphql } from "gatsby"
import { I18n } from "react-i18next"

import Img from "gatsby-image"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import React from "react"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  render() {
    const { recent_news, household, coop, govt, worldwide } = this.props.data
    return (
      <I18n>
        {t => (
          <Layout>
            <SEO title={t("home")} />
            <div className="section bg-dark-blue mt-4 mb-0">
              <Container>
                <Row>
                  <Col>
                    <h1 className="display-5 text-center">{t("mnca_full")}</h1>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="content">
              <div className="section p-0">
                <Container>
                  <Row>
                    <CardDeck>
                      <Card border="primary" className="mb-4">
                        <Img
                          fixed={household.childImageSharp.fixed}
                          alt={t("for_household")}
                          className="card-icon"
                        />
                        <Card.Body className="info">
                          <Card.Title>{t("for_household")}</Card.Title>
                          <Card.Text>{t("for_household_text")}</Card.Text>
                        </Card.Body>
                      </Card>
                      <Card border="primary" className="mb-4">
                        <Img
                          fixed={coop.childImageSharp.fixed}
                          alt={t("for_coop")}
                          className="card-icon"
                        />
                        <Card.Body className="info">
                          <Card.Title>{t("for_coop")}</Card.Title>
                          <Card.Text>{t("for_coop_text")}</Card.Text>
                        </Card.Body>
                      </Card>
                      <div className="w-100 d-none d-sm-block d-lg-none"></div>
                      <Card border="primary" className="mb-4">
                        <Img
                          fixed={govt.childImageSharp.fixed}
                          alt={t("for_govt")}
                          className="card-icon"
                        />
                        <Card.Body className="info">
                          <Card.Title>{t("for_govt")}</Card.Title>
                          <Card.Text>{t("for_govt_text")}</Card.Text>
                        </Card.Body>
                      </Card>
                      <Card border="primary" className="mb-4">
                        <Img
                          fixed={worldwide.childImageSharp.fixed}
                          alt={t("for_int")}
                          className="card-icon"
                        />
                        <Card.Body className="info">
                          <Card.Title>{t("for_int")}</Card.Title>
                          <Card.Text>{t("for_int_text")}</Card.Text>
                        </Card.Body>
                      </Card>
                    </CardDeck>
                  </Row>
                </Container>
              </div>
              <div className="section bg-dark-blue p-0">
                <Container>
                  <Row className="no-gutters justify-content-center text-center">
                    <Col
                      lg="6"
                      className="border-right-lg d-lg-flex align-items-center"
                    >
                      <div className="p-5">
                        <span className="h2 d-block">{t("vision")}</span>
                        <p className="lead">{t("vision_text")}</p>
                      </div>
                    </Col>
                    <Col lg="6" className="d-lg-flex align-items-center">
                      <div className="p-5">
                        <span className="h2 d-block">{t("mission")}</span>
                        <p className="lead">{t("mission_text")}</p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="section news-preview">
                <Container>
                  <Row>
                    <Col>
                      <h2>{t("latest_news")}</h2>
                    </Col>
                  </Row>
                  <Row>
                    {recent_news &&
                      recent_news.edges.map(news => (
                        <Col sm="12" lg="10" key={news.node.id}>
                          <Link
                            className="card hover-effect row no-gutters flex-column flex-md-row flex-fill"
                            to={`/post/${news.node.slug}`}
                          >
                            <Col sm="12" md="4">
                              <Img
                                fluid={news.node.cover.fluid}
                                className="card-img"
                              />
                            </Col>
                            <Col sm="12" md="8">
                              <Card.Body>
                                <div className="list_right">
                                  <h4>{news.node.title}</h4>
                                  <p>{news.node.shortDescription} </p>
                                  <div className="desc">
                                    {news.node.publishedAt}
                                  </div>
                                </div>
                              </Card.Body>
                            </Col>
                          </Link>
                        </Col>
                      ))}
                  </Row>
                </Container>
              </div>
            </div>
          </Layout>
        )}
      </I18n>
    )
  }
}

export default withI18next()(IndexPage)

export const query = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fixed(width: 64, quality: 90) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
    household: file(relativePath: { eq: "icons/ger.png" }) {
      ...fluidImage
    }
    coop: file(relativePath: { eq: "icons/cooperative.png" }) {
      ...fluidImage
    }
    govt: file(relativePath: { eq: "icons/law.png" }) {
      ...fluidImage
    }
    worldwide: file(relativePath: { eq: "icons/worldwide.png" }) {
      ...fluidImage
    }
    recent_news: allContentfulNews(
      filter: { node_locale: { eq: $lng } }
      limit: 3
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          publishedAt(locale: $lng)
          shortDescription
          slug
          cover {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.object,
}
